<template>
  <div class="s-u-order-1" id="leftside">
    <h2 class="s-h s-h--secondary">Editace údajů</h2>
    <div class="s-form">
      <div class="s-tabs s-tabs--id" id="jsTab">
        <div class="s-form-box">
          <CustomerFormComponent
            :customer="loggedCustomer"
            type="edit"
            :index="0"
            @is-valid="valid = $event"
            @getCustomerData="getCustomerData"
          ></CustomerFormComponent>
          <div class="s-form__bottom">
            <span class="position-relative">
              <button
                class="s-btn s-btn--submit"
                :class="{ 's-tooltip__btn': submitButtonDisabled }"
                :disabled="submitButtonDisabled"
                @click="updateCustomer"
              >
                Editovat
              </button>
              <span class="s-tooltip__tooltip" id="what" role="tooltip"> Vyplňte prosím všechna povinná pole </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CustomerFormComponent from '../customers/CustomersFormComponent.vue';

import { XiteApiService } from '../../services/xite.api.service';

export default {
  name: 'UserAccountSummary',

  components: {
    CustomerFormComponent,
  },

  data() {
    return {
      customerData: null,
      valid: false,
    };
  },

  computed: {
    ...mapGetters({
      me: 'me/getMe',
      getCustomer: 'customers/getCustomer',
      customers: 'customers/getAllCustomers',
      localization: 'languages/getBrowserLanguage',
    }),

    loggedCustomer() {
      if (!this.me) {
        return null;
      }

      let CustomerData = {
        name: this.me.name,
        surname: this.me.surname,
        birthdate: this.me.birthdate,
        nationalid: this.me.nationalid,
        nationality: this.me.nationality,
        insurance: this.me.insurance,
        phone: this.me.phone,
        email: this.me.email,
        street: this.me.street,
        city: this.me.city,
        zip: this.me.zip,
        country: this.me.country,
      };

      return CustomerData;
    },

    submitButtonDisabled() {
      return !this.valid;
    },
  },

  methods: {
    updateCustomer() {
      const data = this.customerData;

      XiteApiService.get('SetItems', {
        file: this.me.attributes.id,
        name: data.name,
        surname: data.surname,
        birthdate: data.birthdate,
        nationalid: data.nationalid,
        nationality: data.nationality,
        insurance: data.insurance,
        phone: data.phone,
        email: data.email,
        street: data.street,
        city: data.city,
        zip: data.zip,
        country: data.country,
        username: data.email,
      }).then(() => {
        this.$router.push({ name: 'ReservationsManagement', params: { reloadCustomer: true } });
      });
    },

    getCustomerData(data) {
      this.customerData = data;
    },
  },
};
</script>

<style scoped>
#leftside {
  flex: 1;
}
</style>

<template>
  <div class="s-l-layout__left-side">
    <h2 class="s-h s-h--secondary">Aktualizace profilu</h2>
    <form action="" class="s-form">
      <div class="s-tabs s-tabs--id" id="jsTab">
        <div class="s-tabs__list" role="tablist" aria-label="Výběr osoby k vyplnění">
          <button
            class="s-tabs__tab s-tabs__tab--active"
            role="tab"
            aria-selected="true"
            aria-controls="table-1"
            type="button"
          >
            <span class="s-u-visualy-hidden">
              <!-- Text menit podle toho, zda je osoba jiz vyplnena nebo ne -->
              Zkontrolovat již vyplněné údaje
            </span>
            <span class="d-block s-tabs__check"> </span>
            <span class="s-tabs__date pl-3"> Vladimír&nbsp;Novák&nbsp;(29&nbsp;let) </span>
          </button>
          <button class="s-tabs__tab" role="tab" aria-selected="false" aria-controls="table-2" type="button">
            <span class="s-u-visualy-hidden">
              <!-- Text menit podle toho, zda je osoba jiz vyplnena nebo ne -->
              Vyplnit údaje
            </span>
            <span class="d-block s-tabs__fail"> </span>
            <span class="s-tabs__date pl-3"> Klára&nbsp;Nováková&nbsp;(30&nbsp;let) </span>
          </button>
          <button class="s-tabs__tab" role="tab" aria-selected="false" aria-controls="table-3" type="button">
            <span class="d-block">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 19 19"
                width="14"
                height="14"
                class="d-block"
                aria-hidden="true"
                focusable="false"
                role="img"
              >
                <path
                  fill="#5bbba1"
                  d="M11.3 11.3h5.4c.7 0 1.2-.5 1.2-1.2V8.9c0-.7-.5-1.2-1.2-1.2h-5.4V2.3c0-.7-.5-1.2-1.2-1.2H8.9c-.7 0-1.2.5-1.2 1.2v5.4H2.3c-.7 0-1.2.5-1.2 1.2v1.2c0 .7.5 1.2 1.2 1.2h5.4v5.4c0 .7.5 1.2 1.2 1.2h1.2c.7 0 1.2-.5 1.2-1.2v-5.4z"
                />
              </svg>
            </span>
            <span class="s-tabs__date pl-3"> Přidat&nbsp;další&nbsp;osobu </span>
          </button>
        </div>
        <div class="s-form-box">
          <div
            tabindex="0"
            role="tabpanel"
            id="table-1"
            aria-label="Formulář pro aktualizaci údajů Vladimír Novák (29 let)"
            class="s-form__tabpanel"
          >
            <fieldset class="s-form__fieldset">
              <legend class="s-form__legend">Základní údaje</legend>
              <div class="s-form__col">
                <label for="name" class="s-form__label">
                  Jméno
                  <span class="s-form__check"> </span>
                </label>
                <div class="s-form__input-box">
                  <!-- dat sem jen, kdyz bude spravne vypnene policko -->
                  <span class="s-u-visualy-hidden"> Správně vyplněné políčko </span>
                  <input type="text" name="name" id="name" class="s-form__input" />
                </div>
              </div>
              <div class="s-form__col">
                <label for="surname" class="s-form__label">
                  Příjmení
                  <span class="s-form__fail"> </span>
                </label>
                <div class="s-form__input-box">
                  <!-- dat sem jen, kdyz bude spatne vypnene policko -->
                  <span class="s-u-visualy-hidden"> Špatně vyplněné políčko </span>
                  <input type="text" name="surname" id="surname" class="s-form__input" />
                </div>
                <!-- dat sem jen, kdyz bude spatne vypnene policko -->
                <div class="s-form__error-text">
                  <p>Musíte vyplnit příjmení</p>
                </div>
              </div>
              <div class="s-form__col">
                <label for="date" class="s-form__label">
                  Datum narození
                  <!-- dat sem jen, kdyz bude spravne vypnene policko -->
                  <span class="s-form__check"> </span>
                </label>
                <div class="s-form__input-box">
                  <!-- dat sem jen, kdyz bude spravne vypnene policko -->
                  <span class="s-u-visualy-hidden"> Správně vyplněné políčko </span>
                  <input type="date" name="date" id="date" class="s-form__input s-form__input--date" />
                </div>
              </div>
              <div class="s-form__col">
                <label for="number" class="s-form__label"> Rodné číslo </label>
                <div class="s-form__input-box">
                  <input type="text" name="number" id="number" class="s-form__input" />
                </div>
              </div>
              <div class="s-form__col">
                <label for="country" class="s-form__label"> Národnost </label>
                <div class="s-form__input-box">
                  <input type="text" name="country" id="country" class="s-form__input" />
                </div>
              </div>
              <div class="s-form__col">
                <label for="insurance" class="s-form__label"> Pojišťovna </label>
                <div class="s-form__input-box">
                  <input type="text" name="insurance" id="insurance" class="s-form__input" />
                </div>
              </div>
            </fieldset>
            <fieldset class="s-form__fieldset">
              <legend class="s-form__legend">Kontaktní údaje</legend>
              <div class="s-form__col">
                <label for="tel" class="s-form__label">
                  Telefon
                  <!-- dat sem jen, kdyz bude spravne vypnene policko -->
                  <span class="s-form__check"> </span>
                </label>
                <div class="s-form__input-box">
                  <!-- dat sem jen, kdyz bude spravne vypnene policko -->
                  <span class="s-u-visualy-hidden"> Správně vyplněné políčko </span>
                  <input type="text" name="tel" id="tel" class="s-form__input" />
                </div>
              </div>
              <div class="s-form__col">
                <label for="email" class="s-form__label">
                  E-mail
                  <span class="s-form__fail"> </span>
                </label>
                <div class="s-form__input-box">
                  <span class="s-u-visualy-hidden"> Špatně vyplněné políčko </span>
                  <input type="email" name="email" id="email" class="s-form__input" />
                </div>
                <div class="s-form__error-text">
                  <p>E-mail nemá správný tvar</p>
                </div>
              </div>
              <div class="s-form__col">
                <label for="street" class="s-form__label"> Ulice a číslo </label>
                <div class="s-form__input-box">
                  <input type="text" name="street" id="street" class="s-form__input" />
                </div>
              </div>
              <div class="s-form__col">
                <label for="city" class="s-form__label"> Město </label>
                <div class="s-form__input-box">
                  <input type="text" name="city" id="city" class="s-form__input" />
                </div>
              </div>
              <div class="s-form__col">
                <label for="psc" class="s-form__label"> PSČ </label>
                <div class="s-form__input-box">
                  <input type="text" name="psc" id="psc" class="s-form__input" />
                </div>
              </div>
              <div class="s-form__col">
                <label for="countrycontact" class="s-form__label"> Země </label>
                <div class="s-form__input-box">
                  <input type="text" name="countrycontact" id="countrycontact" class="s-form__input" />
                </div>
              </div>
            </fieldset>
          </div>
          <div
            tabindex="0"
            role="tabpanel"
            id="table-2"
            aria-label="Formulář pro aktualizaci údajů Klára Nováková (šé let)"
            hidden
          ></div>
          <div tabindex="0" role="tabpanel" id="table-2" aria-label="Formulář pro další osobu" hidden></div>
          <button type="submit" class="s-btn s-btn--submit mt-5">Aktualizovat profil</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'UserInfo',
};
</script>

<style scoped>

</style>
